import React, { memo } from "react";
import moduleStyles from "./BrandHeading.module.scss";
import { multiStyles } from "../../utils";
import { useAppContext } from "../../contexts/AppContext";
const S = multiStyles(moduleStyles);

interface BrandHeadingProps {
  theme?: "light" | "dark";
  marginBottom?: 40 | 80;
}

const BrandHeading: React.FC<BrandHeadingProps> = memo(
  (props) => {
    const { theme = "dark", marginBottom } = props;
    const { client } = useAppContext();
    return (
      <div className={S("container", theme, `margin-bottom-${marginBottom}`)}>
        <div className={S("box")}>
          {client.logoUrl ? (
            <img
              className={S("logo")}
              src={client.logoUrl}
              alt={`Logo for ${client.company}`}
            />
          ) : (
            <img
              className={S("logo")}
              src={"/landing/images/logo-placeholder.jpg"}
              alt={`Logo for ${client.company}`}
            />
          )}
        </div>
        <h4 className={S("title")}>{client.company}</h4>
      </div>
    );
  },
  () => true,
);

BrandHeading.displayName = "BrandHeading";

export default BrandHeading;
