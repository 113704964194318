import React from "react";
import moduleStyles from "./PageTitle.module.scss";
import { multiStyles } from "../../utils";
const S = multiStyles(moduleStyles);

interface PageTitleProps {
  title: string;
  theme: "light" | "dark";
  accessoryView?: JSX.Element;
}

const PageTitle: React.FC<PageTitleProps> = (props) => {
  const { title, accessoryView, theme } = props;
  return (
    <div className={S("container")}>
      <h4 className={S("title", theme)}>{title}</h4>
      {accessoryView}
    </div>
  );
};

export default PageTitle;
