import { FC } from "react";
import { multiStyles } from "../../utils";
import moduleStyles from "./Flag.module.scss";
const S = multiStyles(moduleStyles);

export interface FlagProps {
  code: FlagCode;
}

const Flag: FC<FlagProps> = (props) => {
  const { code } = props;
  return <span className={S("flag", `flag-${code}`)} />;
};

export default Flag;

type FlagCode =
  | "ad"
  | "ae"
  | "af"
  | "ag"
  | "ai"
  | "al"
  | "am"
  | "an"
  | "ao"
  | "aq"
  | "ar"
  | "as"
  | "at"
  | "au"
  | "aw"
  | "ax"
  | "az"
  | "ba"
  | "bb"
  | "bd"
  | "be"
  | "bf"
  | "bg"
  | "bh"
  | "bi"
  | "bj"
  | "bl"
  | "bm"
  | "bn"
  | "bo"
  | "br"
  | "bs"
  | "bt"
  | "bw"
  | "by"
  | "bz"
  | "ca"
  | "cc"
  | "cd"
  | "cf"
  | "cg"
  | "ch"
  | "ci"
  | "ck"
  | "cl"
  | "cm"
  | "cn"
  | "co"
  | "cr"
  | "cu"
  | "cv"
  | "cw"
  | "cx"
  | "cy"
  | "cz"
  | "de"
  | "dj"
  | "dk"
  | "dm"
  | "do"
  | "dz"
  | "ec"
  | "ee"
  | "eg"
  | "eh"
  | "er"
  | "es"
  | "et"
  | "eu"
  | "fi"
  | "fj"
  | "fk"
  | "fm"
  | "fo"
  | "fr"
  | "ga"
  | "gb"
  | "gd"
  | "ge"
  | "gg"
  | "gh"
  | "gi"
  | "gl"
  | "gm"
  | "gn"
  | "gq"
  | "gr"
  | "gs"
  | "gt"
  | "gu"
  | "gw"
  | "gy"
  | "hk"
  | "hn"
  | "hr"
  | "ht"
  | "hu"
  | "ic"
  | "id"
  | "ie"
  | "il"
  | "im"
  | "in"
  | "iq"
  | "ir"
  | "is"
  | "it"
  | "je"
  | "jm"
  | "jo"
  | "jp"
  | "ke"
  | "kg"
  | "kh"
  | "ki"
  | "km"
  | "kn"
  | "kp"
  | "kr"
  | "kw"
  | "ky"
  | "kz"
  | "la"
  | "lb"
  | "lc"
  | "li"
  | "lk"
  | "lr"
  | "ls"
  | "lt"
  | "lu"
  | "lv"
  | "ly"
  | "ma"
  | "mc"
  | "md"
  | "me"
  | "mf"
  | "mg"
  | "mh"
  | "mk"
  | "ml"
  | "mm"
  | "mn"
  | "mo"
  | "mp"
  | "mq"
  | "mr"
  | "ms"
  | "mt"
  | "mu"
  | "mv"
  | "mw"
  | "mx"
  | "my"
  | "mz"
  | "na"
  | "nc"
  | "ne"
  | "nf"
  | "ng"
  | "ni"
  | "nl"
  | "no"
  | "np"
  | "nr"
  | "nu"
  | "nz"
  | "om"
  | "pa"
  | "pe"
  | "pf"
  | "pg"
  | "ph"
  | "pk"
  | "pl"
  | "pn"
  | "pr"
  | "ps"
  | "pt"
  | "pw"
  | "py"
  | "qa"
  | "ro"
  | "rs"
  | "ru"
  | "rw"
  | "sa"
  | "sb"
  | "sc"
  | "sd"
  | "se"
  | "sg"
  | "sh"
  | "si"
  | "sk"
  | "sl"
  | "sm"
  | "sn"
  | "so"
  | "sr"
  | "ss"
  | "st"
  | "sv"
  | "sy"
  | "sz"
  | "tc"
  | "td"
  | "tf"
  | "tg"
  | "th"
  | "tj"
  | "tk"
  | "tl"
  | "tm"
  | "tn"
  | "to"
  | "tr"
  | "tt"
  | "tv"
  | "tw"
  | "tz"
  | "ua"
  | "ug"
  | "us"
  | "uy"
  | "uz"
  | "va"
  | "vc"
  | "ve"
  | "vg"
  | "vi"
  | "vn"
  | "vu"
  | "wf"
  | "ws"
  | "ye"
  | "yt"
  | "za"
  | "zm"
  | "zw";
