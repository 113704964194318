import React from "react";
import moduleStyles from "./PageContainer.module.scss";
import { multiStyles } from "../../utils";
import { useAppContext } from "../../contexts/AppContext";
import UserHeader from "../UserHeader";
const S = multiStyles(moduleStyles);

interface PageContainerSubcomponents {
  GenericContainer: React.FC;
  LeftPane: React.FC;
  RightPane: React.FC;
}

const PageContainer: React.FC & PageContainerSubcomponents = ({ children }) => {
  const appContext = useAppContext();
  return (
    <div className={S("container", [!!appContext.user, "authenticated"])}>
      <div className={S("header")}>
        <UserHeader />
      </div>
      <div className={S("body")}>{children}</div>
    </div>
  );
};

// Subcomponents
export const GenericContainer: React.FC = ({ children }) => {
  return (
    <div className={S("container")}>
      <div className={S("body")}>{children}</div>
    </div>
  );
};
export const LeftPane: React.FC = ({ children }) => {
  return (
    <div className={S("pane", "pane-left")}>
      <div className={S("pane-content")}>{children}</div>
    </div>
  );
};
export const RightPane: React.FC = ({ children }) => {
  return (
    <div className={S("pane", "pane-right")}>
      <div className={S("pane-content")}>{children}</div>
    </div>
  );
};

// Associate subcomponents
PageContainer.GenericContainer = GenericContainer;
PageContainer.LeftPane = LeftPane;
PageContainer.RightPane = RightPane;

export default PageContainer;
