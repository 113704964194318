import React from "react";
import { useTranslation } from "react-i18next";
import { IconButton, Monogram, Icons } from "@pushpress/shared-components";
import { multiStyles } from "../../utils";
import { useAppContext } from "../../contexts/AppContext";
import { useAnalytics } from "../AnalyticsProvider";
import Flag from "../Flag";
import IconSelect from "../IconSelect";
import moduleStyles from "./UserHeader.module.scss";

const S = multiStyles(moduleStyles);
const languageMap = {
  en: "us",
  es: "es",
  pt: "br",
} as any;

const UserHeader: React.FC = () => {
  const { t } = useTranslation("common");

  const analytics = useAnalytics();

  const { user, logout, languages, changeLanguage, activeLanguage } =
    useAppContext();

  if (!user) {
    return null;
  }

  const changeLng = (value: string) => {
    const cur = languages.find((lng) => lng.key === value)!;
    changeLanguage(cur);
  };

  const languageToFlag = (key: string) => {
    if (!key) {
      return "us";
    }
    const parts = key?.toLowerCase().split("-");
    if (parts.length === 1) {
      const flag = languageMap[parts[0]];
      return (flag || parts[0]) as any;
    }
    return parts[1] as any;
  };

  const handleLogout = () => {
    analytics.trackEvent("logout", {}, true);
    logout();
  };

  const { firstName, lastName } = user;
  const languagesOptions = languages.map((lng) => ({
    value: lng.key,
    label: lng.nativeName,
  }));

  return (
    <div className={S("header")}>
      <div className={S("name-container")}>
        <Monogram firstName={firstName || ""} lastName={lastName} />
        <span className={S("user-name")}>
          {firstName} {lastName}
        </span>
      </div>
      <div className={S("languages")}>
        <IconSelect
          options={languagesOptions}
          value={activeLanguage?.key!}
          dropdownLabel={t("header.language")}
          onChange={changeLng}
          renderIcon={(value) => <Flag code={languageToFlag(value)} />}
        />
      </div>
      <div className={S("action")}>
        <IconButton
          icon={Icons.Logout}
          size={24}
          color="#000"
          onClick={handleLogout}
          aria-label={t("header.signOut")}
        />
      </div>
    </div>
  );
};

export default UserHeader;
