import React, { createContext, useContext } from "react";
import moduleStyles from "./DescList.module.scss";
import { multiStyles } from "../../utils";
import { Text, TextVariant } from "@pushpress/shared-components";

const S = multiStyles(moduleStyles);

interface DescListSubcomponents {
  Item: React.FC<ItemProps>;
}

export interface DescListProps {
  variant: TextVariant;
  theme?: "light" | "dark";
  className?: string;
  gap?: number;
}

interface DescListState {
  variant: TextVariant;
  theme?: "light" | "dark";
}

const DescListContext = createContext<DescListState | undefined>(undefined);

const DescList: React.FC<DescListProps> & DescListSubcomponents = (props) => {
  const { children, variant, theme, className = "", gap = 4 } = props;
  const contextValue: DescListState = {
    variant,
    theme,
  };
  const rootClassName = `${S("root")} ${className}`;
  return (
    <DescListContext.Provider value={contextValue}>
      <dl className={rootClassName} style={{ gap }}>
        {children}
      </dl>
    </DescListContext.Provider>
  );
};

interface ItemProps {
  term: string;
}

const Item: React.FC<ItemProps> = (props) => {
  const { children, term } = props;
  const contextValue = useContext(DescListContext);
  if (!contextValue) {
    throw new Error("DescList.Item must be used inside DescList.");
  }
  const { variant, theme } = contextValue;
  const termColor = theme === "dark" ? "white" : "wolf-600";
  const descColor = theme === "dark" ? "white" : "wolf-800";

  return (
    <div className={S("item")}>
      <Text
        variant={variant}
        color={termColor}
        className={S("term")}
        component="dt"
      >
        {term}
      </Text>
      <Text
        variant={variant}
        color={descColor}
        className={S("description")}
        component="dd"
      >
        {children}
      </Text>
    </div>
  );
};

DescList.Item = Item;

export default DescList;
