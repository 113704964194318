import { createElement, ElementType, FC } from "react";
import { multiStyles } from "../../utils";
import moduleStyles from "./Text.module.scss";
const S = multiStyles(moduleStyles);

export type TextVariant =
  | "jumbo"
  | "heading-1"
  | "heading-2"
  | "heading-3"
  | "heading-4"
  | "heading-5"
  | "body-lg"
  | "body-md"
  | "body-sm";

export type TextColor =
  | "black"
  | "wolf-900"
  | "wolf-800"
  | "wolf-700"
  | "wolf-600"
  | "wolf-500"
  | "wolf-400"
  | "wolf-300"
  | "wolf-200"
  | "wolf-100"
  | "white";

export type TextStyle = "normal" | "italic";

export interface TextProps {
  component?: ElementType;
  className?: string;
  variant: TextVariant;
  color?: TextColor;
  style?: TextStyle;
}

const Text: FC<TextProps> = (props) => {
  const {
    variant,
    color,
    style,
    children,
    className = "",
    component = "span",
  } = props;
  const rootClassName = `${S(
    variant,
    color ? color : "",
    style ? style : "",
  )} ${className}`;
  return createElement(component, { className: rootClassName }, children);
};

export default Text;
