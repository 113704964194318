import { FC, KeyboardEvent, ReactNode, useRef, useState } from "react";
import { Popover } from "@pushpress/shared-components";
import { multiStyles } from "../../utils";
import moduleStyles from "./IconSelect.module.scss";

const S = multiStyles(moduleStyles);

export interface IconSelectProps {
  options: {
    value: string;
    label: string;
  }[];
  value: string;
  onChange?: (value: string) => void;
  renderIcon: (value: string) => ReactNode;
  dropdownLabel?: string;
}

const IconSelect: FC<IconSelectProps> = (props) => {
  const { options, value, onChange, renderIcon, dropdownLabel } = props;

  const buttonRef = useRef<HTMLButtonElement>(null);
  const [visible, setVisible] = useState(false);

  const handleItemSelect = (v: string) => {
    onChange?.(v);
    setVisible(false);
  };

  return (
    <div className={S("root")} hidden>
      <button
        ref={buttonRef}
        className={S("button")}
        onClick={() => setVisible(true)}
      >
        {renderIcon(value)}
      </button>
      <Popover
        anchorEl={buttonRef.current!}
        visible={visible}
        onClose={() => setVisible(false)}
      >
        <div className={S("dropdown")}>
          {dropdownLabel && <div className={S("label")}>{dropdownLabel}</div>}
          <ul className={S("list")} role="menu">
            {options.map((item) => (
              <Item
                key={item.value}
                value={item.value}
                onSelect={handleItemSelect}
              >
                {item.label}
              </Item>
            ))}
          </ul>
        </div>
      </Popover>
    </div>
  );
};

interface ItemProps {
  value: string;
  onSelect: (value: string) => void;
}

const Item: FC<ItemProps> = (props) => {
  const { value, children, onSelect } = props;

  const handleClick = () => {
    onSelect(value);
  };

  const handleKey = (e: KeyboardEvent<HTMLLIElement>) => {
    if (e.key === "Enter") {
      onSelect(value);
    }
  };

  return (
    <li
      className={S("item")}
      onClick={handleClick}
      onKeyDown={handleKey}
      tabIndex={0}
      role="menuitem"
    >
      {children}
    </li>
  );
};

export default IconSelect;
